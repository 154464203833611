/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { Linkable } from '@/components/blocks/Linkable';
import { PriceDisplay } from '@/components/blocks/PriceDisplay';
import { ProductImage } from '@/components/blocks/ProductImage';
import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { FinalSale } from '@/components/content/OrderItemTable/parts/FinalSale';
import { OrderItemPrice } from '@/components/content/OrderItemTable/parts/Price';
import { OrderItemQuantity } from '@/components/content/OrderItemTable/parts/Quantity';
import { OrderItemTableRowData } from '@/components/content/OrderItemTable/parts/Table';
import { orderItemTableItemDetailsMiniSX } from '@/components/content/OrderItemTable/styles/orderItemTableItemDetailsMini';
import { useOrderItemTableRow } from '@/data/Content/OrderItemTable';
import { useStoreName } from '@/data/Content/StoreName';
import { useLocalization } from '@/data/Localization';
import { ContentContext } from '@/data/context/content';
import { Box, Stack, Typography } from '@mui/material';
import { FC, useContext } from 'react';

// eslint-disable-next-line complexity
export const OrderItemItemDetailsMini: FC = () => {
	const { details, itemDetails } = useContext(ContentContext) as OrderItemTableRowData &
		ReturnType<typeof useOrderItemTableRow>;
	const { name, color, thumbnail, href, prices, loading, attributes, partNumber } = details;
	const labels = useLocalization('OrderItemTable').Labels;
	const additionalSavings = useLocalization('MiniCart').Additional_Savings;
	const { isBtemptd } = useStoreName();
	const priceColor = isBtemptd ? '#6d4f6d' : '#9C304D';

	const uniqueDescriptions =
		itemDetails?.adjustment && itemDetails.adjustment.length >= 1
			? Array.from(
					new Set(
						itemDetails.adjustment
							.filter((eachAdjustment) => eachAdjustment.usage !== 'Shipping Adjustment')
							.map((a) => a.description)
					)
			  )
			: [];

	if (loading) {
		return <ProgressIndicator />;
	}
	return (
		<Stack
			direction="row"
			alignItems="flex-start"
			sx={{
				minHeight: '94px',
				width: '100%',
				padding: '5px 11px 24px 0',
				borderBottom: '2px solid #D8D8D8',
			}}
		>
			{thumbnail ? (
				// TODO Fix potential accessibility issue with img alt name being the same as adjacent link name
				<Box sx={{ width: '92px', padding: '5px 10px 5px 0' }}>
					<ProductImage
						{...{
							src: thumbnail,
							alt: labels.ProductThumbnail.t(),
							isThumbnail: true,
							sx: { cursor: 'auto !important' },
						}}
					/>
				</Box>
			) : null}
			<Stack direction="column" alignItems="flex-start" sx={orderItemTableItemDetailsMiniSX}>
				<Linkable href={href} id={href} data-testid={href}>
					<Typography
						variant="h6"
						data-testid="orderItem-name"
						id="orderItem-name"
						sx={{ fontSize: '13px', color: '#252525', letterSpacing: '0.25px', lineHeight: '20px' }}
					>
						{name}
					</Typography>
				</Linkable>
				<Box
					sx={{
						width: '100%',
						'.MuiTypography-root': {
							fontSize: '13px',
							letterSpacing: '0.25px',
							lineHeight: '20px',
						},
					}}
				>
					<Box sx={{ padding: '3px 0 4px 0', '.MuiTypography-root': { color: '#666666' } }}>
						<OrderItemQuantity mini={true} readOnly={true} />
					</Box>
					{color ? <Typography sx={{ color: '#666666' }}>{`Color: ${color}`}</Typography> : null}
					<Stack direction="row" width="100%" justifyContent="space-between">
						<Box sx={{ '.MuiTypography-root': { color: '#666666' } }}>
							{attributes
								.filter((attr) => attr.usage === 'Defining')
								.map(({ identifier, name, values }, i: number) => (
									<Box key={`minicart-attr-${i}`}>
										<Typography variant="body1" component="span">{`${name}: `}</Typography>
										{values.map(({ value }, i: number) => (
											<Typography
												key={`minicart-attr-${identifier}-${i}`}
												variant="body1"
												component="span"
											>
												{value}
											</Typography>
										))}
									</Box>
								))}
						</Box>
						{prices ? (
							<Box
								sx={{
									'.MuiTypography-root': {
										fontSize: '13px',
										letterSpacing: '0.25px',
										lineHeight: '20px',
										fontWeight: 700,
									},
								}}
							>
								<OrderItemPrice showListPrice={true} />
							</Box>
						) : null}
					</Stack>
				</Box>
				{uniqueDescriptions && uniqueDescriptions.length > 0 ? (
					<Box
						sx={{
							'.MuiTypography-root': {
								fontSize: '12px',
								color: priceColor,
								letterSpacing: '0.25px',
								lineHeight: '20px',
							},
						}}
					>
						{uniqueDescriptions.map((description, index) => (
							<Typography key={index} component="div" variant="body1">
								{description}
							</Typography>
						))}
					</Box>
				) : null}
				{itemDetails?.totalAdjustment?.value && Number(itemDetails.totalAdjustment.value) < 0 ? (
					<Box
						sx={{
							'.MuiTypography-root': {
								fontSize: '12px',
								color: priceColor,
								letterSpacing: '0.25px',
								lineHeight: 'normal',
							},
						}}
					>
						{Math.abs(
							Number(
								itemDetails?.adjustment?.reduce((accumulator, eachAdjustment) => {
									if (eachAdjustment.usage === 'Shipping Adjustment') {
										return accumulator - Number(eachAdjustment.amount);
									}
									return accumulator;
								}, Number(itemDetails.totalAdjustment.value))
							)
						) !== 0 ? (
							<Typography component="span" variant="body1">
								{additionalSavings.t()}
								<PriceDisplay
									currency={itemDetails.totalAdjustment.currency}
									min={Math.abs(
										Number(
											itemDetails?.adjustment?.reduce((accumulator, eachAdjustment) => {
												if (eachAdjustment.usage === 'Shipping Adjustment') {
													return accumulator - Number(eachAdjustment.amount);
												}
												return accumulator;
											}, Number(itemDetails.totalAdjustment.value))
										)
									)}
								/>
							</Typography>
						) : null}
					</Box>
				) : null}
				<FinalSale attributes={attributes} isMiniCart={true} partNumber={partNumber} />
			</Stack>
		</Stack>
	);
};
